import React, { useState } from 'react';
import firebase from 'firebase';
//import { database } from "../../firebase";

function Newsletter() {
    // newsletter form (only email)
    {/*const [input, setInput] = useState("");
    const inputHandler = (e) => {
        setInput(e.target.value);
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (input) {
            console.log(input);
            //add to firebase
            database.collection("emails").add({
                email: input,
                time: firebase.firestore.FieldValue.serverTimestamp(),
            });
            setInput("");
        }
    };  */}

    return (
        <div className="newsletter-area pt-75 pb-40">
            <div className="single-newsletters">
                <div className="newsletter-form">
                    <form /*onSubmit={submitHandler}*/>
                        <input type="email" /*onChange={inputHandler} value={input}*//>
                        <button className="newsbutton"></button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;