import React, { Component } from 'react';
import Slider from 'react-slick';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle2'

const Testimonial =(props) => {
    
        var bg= require('../../assets/img/bg/bgshape.svg');

        var TestimonialData = props.TestimonialData;

        const settings = {
            slidesToShow: 5,
            dots: true,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            centerPadding: '0px',
            autoplaySpeed: 3000,
            centerMode: true,
            responsive: [
              {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 1191,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  centerMode: false,
                }
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              
            ]
        };

        return (
            <section className="testimonail-wrap section-padding" id="testimonial">
                <div className="testimonial-bg" style ={ { backgroundImage: "url("+bg+")" } }>
                </div>
                <div className="container">

                <SectionTitle Title="Mobile Apps"  Titlep="We build most reliable, high-security applications. We have had no unhappy client till today and no one going to another agency after contacting us. Let us consult your idea!" />

                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <Slider className="testimonial-list" {...settings}>
                            {
                                TestimonialData.reviewItem.map(item=> {
                                    return(
                                        <div className="single-testimonial" key={item.id}>
                                            <div className="client-img">
                                                <img src={require (`../../assets/img/service/style7/${item.imgUrl}.png`) } alt="Apps" />
                                            </div>
                                            <div className="client-info">
                                                <h4>{item.cName}</h4>
                                                <p><span>at {item.companyName}</span></p>
                                            </div>
                                            <p>{item.reviewText}</p>
                                        </div>
                                    )
                                })
                            }
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    
}

export default Testimonial
