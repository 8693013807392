import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import Webicon from "../../assets/img/about/web-icon-design-2.png";

 
const Container = tw.div`relative sm:my-24 md:my-24`;
const Content = tw.div`mx-auto max-w-screen-xl`;

const TwoColumn = tw.div`flex flex-col items-center md:flex-row justify-between max-w-screen-xl mx-auto`;
const ImageColumn = tw.div` ml-10 mr-10 md:w-6/12 lg:block lg:w-5/12 flex-shrink-0`;
const ToggleColumn = tw.div` ml-10 mr-10 sm:w-6/12 md:w-11/12 lg:w-6/12 lg:pl-10 lg:block`;

const FAQContent = tw.div`md:ml-10 lg:ml-10`;
const Subheading = tw(SubheadingBase)`mb-4 text-center md:text-left lg:text-left`;
const Heading = tw(SectionHeading)` md:text-left lg:text-left`;
const Description = tw.p`border-solid border border-blue-500 shadow-sm sm:w-11/12 p-4  md:text-left lg:w-auto max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none text-sm sm:text-base lg:text-lg font-normal mt-4 text-secondary-400`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-4 border-solid border border-blue-500 px-8 py-4 rounded-lg md:w-full lg:mr-10`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span` text-base lg:text-base font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-blue-500 text-gray-100 p-1 rounded-full group-hover:bg-blue-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`); 

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,

  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-24 bg-right`
]);


export default ({
  subheading = "Partnership With Us",
  heading = (
    <>
      What we <span tw="text-blue-700">Offer? </span>
    </>
  ),
  description = "Our approach is grounded in strong, transparent communication and a deep respect for our clients. You’ll always get our best council, and we’ll always act in your best interest. We work hard to demystify the web bulding process and ensure you’re with us at every step.",
  imageSrc = Webicon,
  imageContain = true,
  imageShadow = false,  
  faqs = null
}) => {
  

  const defaultFaqs = [
    {
      question: "Customized Desktop, Tablet & Phone Friendly Websites",
      answer:
        "We set to work designing the project to plan — responsive theme development, page layouts, menus, UX, and other interactive elements. You get access to work on pages as we build out custom UX elements and connect any third-party extensions and integrations."
    },
    {
      question: "Support After Launching Your Website",
      answer:
        "Launching your website is just the beginning. After your site goes live, you get proactive, reliable support from us on an ongoing basis. We will provide you training, documentation, user acceptance testing, and analytics set up. However, this is not a type keeping you dependent from us, conversely, your website is fully independet from us. You can edit, change your website, transfer to another hosting server, etc."
    },
    {
      question: "6 x Times Higher Performance",
      answer:
        "6x performance and speed, where the Enhanced Security Suite will speed up and protect your website. You run your website with guaranteed power, performance, speed and enhanced security, without caring about them for 3 years. After 3 years you can continue with us for further or transfer your website whereever you want."
    },
    {
      question: "How can I reach you for support ?",
      answer:
        "Simply use the contact form or the contact details on this website."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <ImageColumn>
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
            </FAQContent>
          </ImageColumn>

          <ToggleColumn>            
            <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ 
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group" 
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>                                 
                  </FAQ>
                ))}
            </FAQSContainer>            
          </ToggleColumn>          
        </TwoColumn>

        
      </Content>
    </Container>
  );
};
