import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "../../components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import UniqueIcon from "../../assets/img/about/WeAreUnique.jpg";

const Container = tw.div`relative`;
const Content = tw.div`mx-auto max-w-screen-xl`;

const TwoColumn = tw.div`flex flex-col items-center md:flex-row justify-between max-w-screen-xl mx-auto`;
const ToggleColumn = tw.div` ml-10 mr-10 mb-20 lg:-mr-12 sm:w-6/12 md:w-7/12 md:flex-shrink lg:w-7/12 lg:block`;
const ImageColumn = tw.div` ml-10 mr-10 md:w-6/12 lg:-mt-24 lg:block lg:pl-24 lg:w-6/12 flex-shrink-0`;

const FAQContent = tw.div``;
const Heading = tw(SectionHeading)` md:text-left lg:text-left`;
const Description = tw.p`border-solid border border-blue-500 shadow-sm sm:w-11/12 p-4 md:mr-10 md:text-left lg:w-auto max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none text-sm sm:text-base lg:text-lg font-normal mt-4 text-secondary-400`;

const FAQSContainer = tw.dl`mt-12 lg:ml-10`;
const FAQ = tw.div`cursor-pointer mt-4 border-solid border border-blue-500 px-8 py-4 rounded-lg lg:mr-10`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span` text-base lg:text-base font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-blue-500 text-gray-100 p-1 rounded-full group-hover:bg-blue-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-32 bg-left`
]);

export default ({
  heading = (
    <>
      What Makes Us <span tw="text-blue-700">Unique?</span>
    </>
  ),
  description = "We specialize in designing professional, highly responsive, custom websites with all the modern functionalities you need to succeed. Plus, we offer free SSD hosting, domain registration, and many unlimited features that you won't find anywhere else. These free services are especially designed for startups looking to establish themselves online. Trust us to provide you with the best opportunity to stand out!",
  imageSrc = UniqueIcon,
  imageContain = true,
  imageShadow = false,  
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Free SSD web hosting for 3 years",
      answer:
        "As we launch your website, for 3 years, you do not need to care about it. All services in your order package will be included for you for 3 years, except domain registartion which is for only 1 year. You can withdraw your website from this host and transfer it to another host server any time, for free. However, you will not be paid back for web hosting as we provide it for free."
    },
    {
      question: "Free unlimited email addresses",
      answer:
        "Yes, that is true, you get free unlimited email addresses with your domain name. E.g. your domain name is https://intellapps.io, you can create unlimited email addresses with your domain name like info@intellapps.io,  support@intellapps.io,  consult@intellapps.io, etc. Each of your email box will have up 1GB/mailbox storage and up to 250 emails/hour capacity for start."
    },
    {
      question: "Maintenance & Updates for 3 years",
      answer:
        "The things that are included in your 3-years-package, we maintain them as they are, we renew them automatically, and we update them continuosly, so that you do not need to care about them."
    },
    {
      question: "Free domain (+subdomains) registation 1 year",
      answer:
        "We register your domain name (non-premium domains) for 1 year for free. Then you can add unlimited subdomains onto your primary domain for free. This means that if your primary doman name is https://intellapps.io, you can add multiple subdomains like posts.intellapps.io, order.intellapps.io, blog.intellapps.io, news.intellapps.io, etc."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <ToggleColumn>            
            <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ 
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                  <Question>
                        <QuestionText>{faq.question}</QuestionText>
                        <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                        </QuestionToggleIcon>
                      </Question>
                      <Answer
                        variants={{
                          open: { opacity: 1, height: "auto", marginTop: "16px" },
                          collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                        }}
                        initial="collapsed"
                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                      >
                        {faq.answer}
                      </Answer>                                 
                  </FAQ>
                ))}
            </FAQSContainer>            
          </ToggleColumn>

          <ImageColumn>
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
            <FAQContent>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
            </FAQContent>
          </ImageColumn>
        </TwoColumn>
      </Content>
    </Container>
  );
};
