import React from 'react'; 
import BannerHome from '../../components/Banner/BannerHome';
import OurCompanySection from './OurCompanySection';
import Service from './ServiceSection';
import Technology from './TechnologySection';
import IndustrySection from './IndustrySection';
import IndustryData from '../../components/Industry/IndustryData';
import CTA from './CtaSection';
import Brand from '../../components/Common/Brand';
import ScrollToTop from '../../components/Common/ScrollTop';

const HomeMain = () => {

	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerHome />
			{/* banner-start */}

			{/* About-area-start */}
			<OurCompanySection />
			{/* About-area-end */}

			{/* Service-area-start */}
			<Service />
			{/* Service-area-end */}

			{/* Technology-start */}
			<Technology />
			{/* Technology-end */}

			{/* Industries-start */}
			<IndustrySection IndustryData={IndustryData} />
			{/* Industries-end */}

			{/* CTA-start */}
			<CTA />
			{/* CTA-end */}

			{/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeMain;