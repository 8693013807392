import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { Tab, TabList } from 'react-tabs';
import SectionTitle from '../../components/Common/SectionTitle';
import { Subheading as SubheadingBase } from "../../components/misc/Headings.js";

import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";


const Container = tw.div`relative sm:my-24 md:my-24`;
const Content = tw.div`mx-auto max-w-screen-xl`;

const TwoColumn = tw.div`flex flex-col items-center md:flex-row justify-between max-w-screen-xl mx-auto`;
const ToggleColumn = tw.div` ml-10 mr-10 sm:w-6/12 md:w-11/12 lg:w-6/12 lg:pl-10 lg:block`;

const FAQSContainer = tw.dl`mt-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center md:text-left lg:text-left`;
const FAQ = tw.div`cursor-pointer mt-4 border-solid border border-blue-500 px-8 py-4 rounded-lg md:w-full lg:mr-10`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span` text-base lg:text-base font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-blue-500 text-gray-100 p-1 rounded-full group-hover:bg-blue-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);


const tabStyle = tw`text-sm leading-3`;


export default ({
  subheading1 = "Services included in the Package",
  subheading2 = "Free Items included in the Package",
  /*heading = (
    <>
      What we <span tw="text-blue-700">Offer? </span>
    </>
  ),*/
  faqs1 = null,
  faqs2 = null
}) => {


  const defaultFaqs1 = [
    {
      question1: "Customized Desktop, Tablet & Phone Friendly Websites",
      answer1:
        "We set to work designing the project to plan — responsive theme development, page layouts, menus, UX, and other interactive elements. You get access to work on pages as we build out custom UX elements and connect any third-party extensions and integrations."
    },
    {
      question1: "Unlimited SSD Web Space",
      answer1:
        "Solid State Drives (SSD) are state of the art technology that dramatically improves the time between a visitor asking for a file and the server delivering that file. It’s one of the advancements that adds up to lightning-fast page loads. Unlimited SSD storage allows you to use and grow your web hosting account without incurring any additional storage fees. Please, note that the storage is available for you to use for your website files only and not for data archiving including email, computer files, backup, etc."
    },
    {
      question1: "Unmetered bandwidth",
      answer1:
        "There is no limitation for the amount of visitor traffic your website can receive or the amount of content you upload to your website as long as you comply with hosting Terms of Service. If your website demands resources that present a risk to the stability, performance, or uptime of host servers, you will get notification to take corrective action or be asked to choose a plan more suitable to your needs."
    },
    {
      question1: "Unlimited number of website",
      answer1:
        "There is no limitation for the number of websites you store in your one hosting account as there is limitation for the number of your website from the hosting space perspective."
    },
    {
      question1: "Unlimited MySQL databases",
      answer1:
        "Get unlimited MySQL database hosting to satisfy the needs of your any business. Online stores and promotional websites of all types need a secure and reliable back end that serves as the engine for their CMS and all their web-based database applications. Implementing secure, reliable processing for your website doesn’t have to cost a lot or require you to hire a database expert. You get MySQL hosting solutions that gives your site ultra-fast processing speed, scalability, and bulletproof security."
    },
    {
      question1: "6 x Times Higher Performance, Guaranteed power, and enhanced security.",
      answer1:
        "6x performance and speed and Enhanced Security Suite will speed up and protect your website."
    },
    {
      question1: "No hidden fees",
      answer1:
        "There is no hidden fee for any of these services inculuded to the package. Website pricing starts with $899 and depends on only page number and addition of special functionalities, apps which is the case for all website developer companies. However, we still promise the lowest pricing. We do not just promise but we guarantee the lowest pricing."
    },
  ]
  const defaultFaqs2 = [
    {
      question2: "Free hosting for 3 years",
      answer2:
        "As we launch your website, you do not need to care about it for 3 years. All services in this column are free for you for 3 years except domain registartion which is free for 1 year. The website can be withdrawn from this hosting and transferred to any another host server at any time. However, you will not be paid back for web hosting as we provide it for free."
    },
    {
      question2: "Free premium wildcard SSL certificate -3 years",
      answer2:
        "An SSL (Secure Sockets Layer) certificate is a digital certificate that authenticates a website’s identity and enables an encrypted connection. SSL is a security protocol that creates an encrypted link between a web server and a web browser while a Wildcard SSL Certificate , along with your main domain –*.intellapps.io, will automatically secure your all subdomains – web.intellapps.io, order.intellapps.io, product.intellapps.io, or https://intellapps.io/product, etc."
    },
    {
      question2: "Free unlimited email addresses -3 years",
      answer2:
        "Yes, that is true, you get free unlimited email addresses with your domain name. E.g. your domain name is www.intellapps.io, you can create unlimited email addresses like info@intellapps.io, mail@intellapps.io, admin@intellapps.io, etc. Each of your email box will have 1GB/mailbox storage and up to 250 emails/hour capacity."
    },
    {
      question2: "Free protection power CDN -3 years",
      answer2:
        <TabList className={tabStyle}>
          <Tab class="text-sm leading-3">
            <p>Free Protection Power Pro includes:</p>
            <p>Enhanced content delivery network</p>
            <p>Malware removal assistance</p>
            <p>Daily malware scan</p>
            <p>Advanced DDoS protection</p>
            <p>Website vulnerability scan</p>
          </Tab>
        </TabList>
    },
    {
      question2: "Free Maintenance & Updates -3 years",
      answer2:
        "Launching your website is just the beginning. After your site goes live, you get proactive, reliable support from us on an ongoing basis.  The services that are included in your 'Start-Up package', we maintain them as they are, we renew them automatically, and we update them continuosly, so that you do not need to care about them. However, this is not a type keeping you dependent from us, conversely, your website is fully independet from us. You can edit, change your website, transfer to another hosting server, etc."
    },
    {
      question2: "Free automated website backup -3 years",
      answer2:
        "Free automated website backup (1GB). Within 24 hours after placing your website to the server, a full backup of all the files and folders in your website is completed. From that point on, whenever backup creates a restore point, it compares the current state of the website with the full backup. It then backs up only the data on the website that is different from what is in the backup. If only a part of a file has changed, the backup creates a backup of only the changed part instead of the entire file which saves time and disk space."
    },
    {
      question2: "Free domain (+subdomains) registation -1 year",
      answer2:
        "We register your domain name for 1 year for free (non-premium domains). Then you can add unlimited subdomains onto your primary domain for free. This means that if your primary doman name is e.g. www.intellapps.io, you can have multiple subdomains like posts.intellapps.io, order.intellapps.io, blog.intellapps.io, news.intellapps.io, etc. "
    },

  ];

  if (!faqs1 || faqs1.length === 0) faqs1 = defaultFaqs1;
  if (!faqs2 || faqs2.length === 0) faqs2 = defaultFaqs2;

  const [activeQuestionIndex1, setActiveQuestionIndex1] = useState(null);
  const [activeQuestionIndex2, setActiveQuestionIndex2] = useState(null);

  const toggleQuestion1 = questionIndex1 => {
    if (activeQuestionIndex1 === questionIndex1) setActiveQuestionIndex1(null);
    else setActiveQuestionIndex1(questionIndex1);
  };
  const toggleQuestion2 = questionIndex2 => {
    if (activeQuestionIndex2 === questionIndex2) setActiveQuestionIndex2(null);
    else setActiveQuestionIndex2(questionIndex2);
  };

  return (
    <Container>
      <Content>
        <div id="blog" className="process style6 white-bg pt-20 pb-20 md-pt-20 md-pb-20">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title4 mb-50 text-center"
              subtitleClass="sub-text new-text"
              subtitle="Pricing - special offer for Start-ups"
              titleClass="title new-title"
              title="Our special affordable 'Start-up' package for $899"
            />
          </div>
        </div>
        <TwoColumn>
          <ToggleColumn>
            <FAQSContainer>
              {subheading1 ? <Subheading>{subheading1}</Subheading> : null}
              {/*<Heading>{heading}</Heading>*/}
              {faqs1.map((faq, index) => (
                <FAQ
                  key={index}
                  onClick={() => {
                    toggleQuestion1(index);
                  }}
                  className="group"
                >
                  <Question>
                    <QuestionText>{faq.question1}</QuestionText>
                    <QuestionToggleIcon>
                      {activeQuestionIndex1 === index ? <MinusIcon /> : <PlusIcon />}
                    </QuestionToggleIcon>
                  </Question>
                  <Answer
                    variants={{
                      open: { opacity: 1, height: "auto", marginTop: "16px" },
                      collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex1 === index ? "open" : "collapsed"}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    {faq.answer1}
                  </Answer>
                </FAQ>
              ))}
            </FAQSContainer>

          </ToggleColumn>

          <ToggleColumn>
            <FAQSContainer>
              {subheading2 ? <Subheading>{subheading2}</Subheading> : null}
              {/*<Heading>{heading}</Heading>*/}
              {faqs2.map((faq, index) => (
                <FAQ
                  key={index}
                  onClick={() => {
                    toggleQuestion2(index);
                  }}
                  className="group"
                >
                  <Question>
                    <QuestionText>{faq.question2}</QuestionText>
                    <QuestionToggleIcon>
                      {activeQuestionIndex2 === index ? <MinusIcon /> : <PlusIcon />}
                    </QuestionToggleIcon>
                  </Question>
                  <Answer
                    variants={{
                      open: { opacity: 1, height: "auto", marginTop: "16px" },
                      collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex2 === index ? "open" : "collapsed"}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    {faq.answer2}
                  </Answer>
                </FAQ>
              ))}
            </FAQSContainer>

          </ToggleColumn>
        </TwoColumn>
        <div className="btn-part ml-80 mt-30">
          <Link to="/contact">
            <a className="readon learn-more">Ask If You'r Eligible</a>
          </Link>
        </div>

      </Content>
    </Container>
  );
};
