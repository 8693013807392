import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';



//Custom Components

import Home from '../pages/home';
import About from '../pages/about';
import Services from '../pages/service';
import HowWeWork from '../pages/ourwork';
import ServiceMain from '../pages/service/service-main';
import SoftwareDevelopment from '../pages/service/software-development';
import WebDevelopment from '../pages/service/web-development';
import AnalyticSolutions from '../pages/service/analytic-solutions';
import hostingAndCloud from '../pages/service/hosting-cloud-services'
import AppDevelopment from '../pages/service/app-development';
import Branding from '../pages/service/branding';
import Contact from '../pages/contact';
import Faq from '../pages/faq';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    return (
        <div className='App'>
            <Router>  
                <LoadTop />          
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/service" exact component={Services} />
                    <Route path="/ourwork" component={HowWeWork} />
                    <Route path="/service/service-main" component={ServiceMain} />
                    <Route path="/service/software-development" component={SoftwareDevelopment} />
                    <Route path="/service/web-development" component={WebDevelopment} />
                    <Route path="/service/analytic-solutions" component={AnalyticSolutions} />
                    <Route path="/service/hosting-cloud-services" component={hostingAndCloud} />
                    <Route path="/service/app-development" component={AppDevelopment} />
                    <Route path="/service/branding" component={Branding} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/faq" component={Faq} />
                    <Route component={Error} />
                </Switch>
            </Router>
            
        </div>
    );
}

export default App;
