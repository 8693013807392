import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-6 text-right md-text-center md-mb-10 order-last">
                        <ul className="copy-right-menu">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/service">Services</Link></li>
                            <li><Link to="/ourwork/how-we-work">How We Work</Link></li>
                            <li><Link to="/ourwork/how-we-work">FAQs</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <div className="copyright md-text-center md-mb-10">
                            <p>Copyright © {new Date().getFullYear()} <Link to="/"> IntellApps™</Link>. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;