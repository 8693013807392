import React, { Component } from 'react'
import SectionTitle from '../../components/Common/SectionTitle'

const IndustrySection = (props) => {

    var IndustryData = props.IndustryData;

    return (
        <section className="services-wrap section-padding pt-50" id="service">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text style-bg"
                    subtitle="We Work With Any Industry"
                    titleClass="title"
                    title="Best Solutions, For All Industries"
                    effectClass="heading-line"
                />

                <div className="row">
                    {
                        IndustryData.serviceBoxItem.map(item=> {
                            return(
                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12" key={item.id} >
                                <div className="single-service-box" data-aos="fade-up" data-aos-duration={800}>
                                    <div className="service-icon">
                                        <img className="icon-img" src={require (`../../assets/img/industry/${item.imgUrl}`)} alt="industry" />
                                    </div>
                                    <h4>{item.title}</h4>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default IndustrySection
