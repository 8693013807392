import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/4.jpg';

import { Link } from 'react-router-dom';
import Brand from '../../components/Common/Brand';
import SectionTitle from '../../components/Common/SectionTitle';
import ScrollToTop from '../../components/Common/ScrollTop';

// About Image
import aboutImg1 from '../../assets/img/about/5.png';

const cloudAndHosting = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='Services'
                activeMenu='/hosting-cloud-services'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Hosting & Cloud Services'
                pageCategory='Services'
                pageName='Hosting & Cloud Services'
            />
            {/* breadcrumb-area-end */}

            {/* About Section Start */}
            <div className="about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Hosting & Cloud Services"
                                titleClass="title"
                                title="Enjoy Unlimited SSD Web Space or Cloud Storage with Our Web Hosting Service"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Our state-of-the-art web hosting service includes the latest Solid State Drive (SSD) technology, which drastically reduces the time it takes for your website to load. With lightning-fast page loads, you can provide your visitors with a seamless browsing experience. Our hosting service comes with free unlimited SSD storage for Start-Ups, so you can use and grow your web hosting account without worrying about any additional storage fees."
                                secondDescClass="desc"
                                secondDescription="Plus, you can take advantage of public cloud solutions to maximize your business focus. Our public cloud offers high-performance, secure, and cost-effective business tools that don’t require any in-house infrastructure or staff to set up. If you need to retain total control over your cloud solution, you can choose our private cloud option. We're always happy to discuss your needs and create a custom plan that works for you."
                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Learn more</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-160">
                            <div className="images">
                                <img src={aboutImg1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
            <Footer footerClass="footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default cloudAndHosting;