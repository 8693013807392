const IndustryData = {
    serviceBoxItem: [
        {
            id:1,
            title:"Education",
            imgUrl:"1.png"
        },
        {
            id:2,
            title:"e-Commerce",
            imgUrl:"2.png"
        },
        {
            id:3,
            title:"Healthcare",
            imgUrl:"3.png"
        },
        {
            id:4,
            title:"Fintech",
            imgUrl:"4.png"
        },
        {
            id:5,
            title:"Machine learning",
            imgUrl:"5.png"
        },
        {
            id:6,
            title:"Data Mining",
            imgUrl:"6.png"
        },
        {
            id:7,
            title:"Entertainment",
            imgUrl:"7.png"
        },
        {
            id:8,
            title:"AI",
            imgUrl:"8.png"
        },
        {
            id:9,
            title:"Manufacturing",
            imgUrl:"9.png"
        },
        {
            id:10,
            title:"Logistics",
            imgUrl:"10.png"
        },
        {
            id:11,
            title:"Media",
            imgUrl:"11.png"
        },
        {
            id:11,
            title:"Social Media",
            imgUrl:"12.png"
        }
    ],

}

export default IndustryData

