import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';

// Service Image Icons
import roundImg from '../../assets/img/about/round.png';
import aboutImg from '../../assets/img/about/about1.png';

const About = () => {

    return (
        <div id="about" className="about style3 pt-120 pb-40 md-pt-74 md-pb-40">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <SectionTitle
                            sectionClass="sec-title4 mb-30"
                            subtitleClass="sub-text style-bg"
                            subtitle="Our Company"
                            titleClass=" title title3 pb-20"
                            title="We Create Result-Oriented Dynamic Applications"
                            effectClass="heading-line"
                        />
                        <SectionTitle
                            sectionClass="sec-title4 mb-30"
                            descClass="desc desc2 text-justify"
                            description="Based in New Zealand, IntellApps is at the forefront of intelligent application development, including AI-powered automation and smart applications for research, as well as web and mobile applications. We are pioneers in crafting intelligent, dynamic applications that integrate cutting-edge technologies, including automation and artificial intelligence."                  
                            secondDescClass="margin-0 text-justify pt-20"
                            secondDescription="Our commitment at IntellApps is not just to meet your digital needs but to redefine them. Our expert team collaborates closely with each client to understand their unique business challenges. Whether it’s automating routine tasks, embedding smart AI algorithms to process data, or developing self-learning systems to improve user interactions, our solutions are designed to bring you measurable results and a competitive edge. Contact IntellApps today to discover how our intelligent applications can transform your business operations and drive your success in the digital arena."
                
                        />
                        <div className="btn-part mt-40">
                            <Link className="readon discover more" to="/about">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-lg-7 md-pt-10">
                        <div className="animation-image ">
                            <div className="pattern-img d-md-flex justify-content-center">
                                <img
                                    src={roundImg}
                                    alt=""
                                />
                            </div>
                            <div className="middle-img md-pt-60">
                                <img
                                    className="dance3"
                                    src={aboutImg}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;