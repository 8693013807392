//src/firebase.js

//Import firebase from node_modules
import firebase from 'firebase';
//import { initializeApp } from "firebase"

//you have copy and paste this config from your Firebase Console
const firebaseConfig = {
    apiKey: "AIzaSyA8RO9pg1lofw4uNLeCnBdy1RJBrEKYUGY",
    authDomain: "intap-2ab80.firebaseapp.com",
    projectId: "intap-2ab80",
    region: "us-central1",
    storageBucket: "intap-2ab80.appspot.com",
    messagingSenderId: "571673876852",
    appId: "1:571673876852:web:e2c76bdb01aff77839b421",
    measurementId: "G-SJ47QGRNH8"
};


//initialize a Firebase instance
firebase.initializeApp(firebaseConfig);

//initialize Cloud Functions through Firebase
firebase.functions();
// database=firebase.database();
// Initialize another app with a different config
// const secondaryApp = firebase.initializeApp(secondaryAppConfig, "secondary");
// Access services, such as the Realtime Database
// secondaryApp.database();

//export the `firebase` namespace to import it in src/App.js
export default firebase

//export {database}
