import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import VideoStyleThree from '../../components/Video/VideoStyleThree';
import OurExpertise from "./OurExpertise";
import ExtraFeatures1 from "./ExtraFeatures1";
import ExtraFeatures2 from "./ExtraFeatures2";
import Project from './ProjectSection';

import BgImage from "../../assets/img/about/team.jpg";
import danceEffectImg from '../../assets/img/about/effect-1.png';


import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';

const AboutMain = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="about" className="about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Expertise"
                                    titleClass="title pb-36"
                                    title="Expert Developers to Bring Your Vision to Life"
                                    descClass="desc pb-34"
                                    description="Our team of highly skilled developers comprises the best talent from around the world, including experts based across New Zealand who are dedicated to delivering exceptional results for our clients. With years of experience and a deep understanding of the latest technologies and trends, our professional developer team is here to help bring your vision to life."
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription="Whether you're a startup looking to establish a strong online presence, or an established business seeking to expand your digital offerings, our professional developer team is here to help. Contact us today to learn more about how we can help you achieve your goals."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/service">Learn-More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 md-mb-30">
                            <div className="animation-shape">
                                <div className="team-image">
                                    <img src={BgImage} alt=""/>
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AboutMain;