const TestimonialData = {
    reviewItem: [
        {
            id:1,
            imgUrl:"1",
            cName:"Enterprise Mobile Apps",
            cPosition:"",
            companyName:"IntellApps",
            reviewText:"Need a reliable App Developer Agency? You are on a right place! Using business app dashboards, track expenses, create reports, manage security records, etc. "
        },
        {
            id:2,
            imgUrl:"2",
            cName:"Augmented Realit Apps",
            cPosition:"",
            companyName:"IntellApps",
            reviewText:"We build Augmented Reality Applications, have you an idea? Let yourself experience the augmented reality on your Android device or iPhone"
        },
        {
            id:3,
            imgUrl:"3",
            cName:"Social Network Apps",
            cPosition:"",
            companyName:"IntellApps",
            reviewText:"Technological progress shifts social networking from web-based environment to hybrid world - web and mobile or to pure mobile applications."
        },
        {
            id:4,
            imgUrl:"4",
            cName:"Smart Device Apps",
            cPosition:"",
            companyName:"IntellApps",
            reviewText:"We create best applications that smart wearable devices use application-enabled advanced computing and wireless technologies to process the inputs."
        },
        {
            id:5,
            imgUrl:"5",
            cName:"Personal Assistance Apps",
            cPosition:"",
            companyName:"IntellApps",
            reviewText:"AI-based personal assistant apps, for purposes of Large-scale personalization, Emotional state analyzer, and Interaction with a minimum number of stages."
        },
        {
            id:6,
            imgUrl:"6",
            cName:"Geo-Service Apps",
            cPosition:"",
            companyName:"IntellApps",
            reviewText:"Location-Based Services Apps that are used for Navigation, Search and advertising, Infotainment, Tracking, Games and augmented reality."
        }
    ],

}


export default TestimonialData
