import React from 'react';
import {Tabs, TabList, TabPanel } from 'react-tabs';

import SectionTitle from '../../components/Common/SectionTitle';
import projectImg1 from '../../assets/img/process/style2/2.jpg';

const PortfolioTabTwo = () => {

    const tabStyle = 'nav nav-tabs';
    return (
        <div className="tab-area">
            <Tabs>
                <div className="row">
                    <div className="col-lg-8 pr-40 md-pr-14 md-mb-30">
                        <div className="tab-content">
                            <TabPanel>
                                <div className="image-wrap">
                                    <img src={projectImg1} alt="Process Tab" />
                                </div>
                            </TabPanel>  
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <TabList className={tabStyle}>
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="IntellApps is an innovative, youthful web solutions company supported by committed and skilled software developers, seasoned technical professionals, passionate designers, and creative mobile application developers."
                                secondDescClass="desc"
                                secondDescription="We have been proactively offering a wide spectrum of web solutions services to diverse clients. We manage the complete web landscape of our clients by providing smart and skillful services while bringing out creativity and innovation during every step at minimum expenses.
                                With a client-first approach whether big or small, we work to enhance the importance of product and its users by exceeding the expectations in creativity or functionality."
                            />   
                        </TabList>
                    </div>
                </div>
            </Tabs>
        </div>
    );
}
export default PortfolioTabTwo;