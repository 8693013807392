import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';
import Newsletter from '../../Common/Newsletter';

import footerLogo1 from '../../../assets/img/logo/white-logo.png';


const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'footer'}>
            <div className="footer-top style={bgStyle1}">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo h-60 w-60">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p>Owning cyberspace through IntellApps is one of the most promising ways to communicate with modern generation.</p>
                            </div>
                            <ul className="footer-social md-mb-30">
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100084242160126" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-pinterest"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-instagram"></i></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">Our Services</h3>
                            <ul className="site-map">
                                <li><Link to="/service/Web-development">Web Development</Link></li>
                                <li><Link to="/service/software-development">Software Development</Link></li>
                                <li><Link to="/service/app-development">App Development</Link></li>
                                <li><Link to="/service/hosting-cloud-services">Hosting & Cloud Services</Link></li>
                                <li><Link to="/service/analytic-solutions">Analytic Solutions</Link></li>
                                <li><Link to="/service/branding">Company Branding</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contact Info</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">1 Wagener Place, Mt Albert, Auckland 1025, NZ</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <a href="tel:++64 22 473–66-22">+64 22 473–66-22</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <a href="mailto:consult@intellapps.io">consult@intellapps.io</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        Opening Hours: 08:30 - 17:00
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <h3 className="widget-title">Newsletter</h3>
                            <p className="widget-desc">Be informed with our new services by adding impact to your inbox.</p>
                            <p className="widget-desc">** Your email will never be shared with a third party.</p>
                            <p>
                                {/* newsletter-area-start */}
                                <Newsletter />
                                {/* newsletter-area-end */} 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;