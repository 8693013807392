import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import PortfolioTab from '../../components/Elements/Tab/PortfolioTab'


const Project = () => {

    return (
        <React.Fragment>
            <div id="rs-portfolio" className="project style8 modify1 pt-110 pb-120 md-pt-70 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 mb-30 text-center"
                        subtitleClass="sub-text style-bg"
                        subtitle="We help brands with"
                        titleClass="title new-title"
                        title="Finest Projects Built With Latest Technologies"
                    />
                    <PortfolioTab />

                </div>
            </div>
        </React.Fragment>
    )
}

export default Project