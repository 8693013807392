import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcessTwo from '../../components/Process/SingleProcessTwo';

import shapeImg from '../../assets/img/process/circle.png';

const Process = () => {

    return (
        <div className="gray-bg">
            <div className="process modify1 pt-120 pb-120 sm-mb-80 md-pt-74 md-pb-80">
                <div className="shape-animation">
                    <div className="shape-process">
                        <img className="dance2" src={shapeImg} alt="images" />
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center items-center">
                        <div className="col-lg-4 sm-pl-80 pr-40 md-pl-70 md-pr-14 md-pb-80 lg-pl-20">
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text new"
                                subtitle="Working Process"
                                titleClass="title white-color"
                                title="Our Working Process - How We Work For Our Customers?"
                            />

                            <div className="btn-part mt-40 hidden lg:block">
                                <Link className="readon started" to="/contact">Contact Us</Link>
                            </div>
                        </div>
                        <div className="col-lg-8 sm-pl-40 sm-pr-20">
                            <div className="row">
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="addon-number"
                                        prefixClass="number-area"
                                        prefix="1"
                                        titleClass="title"
                                        Title="Project Research"
                                        descClass="number-txt"
                                        Description="Every project starts with the strategy. What do we need to achieve with it? Who does it need to reach? And how do we need to design it so it fulfils all of those goals?"
                                    />
                                </div>
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="addon-number"
                                        prefixClass="number-area green-bg"
                                        prefix="2"
                                        titleClass="title"
                                        Title="Wireframing"
                                        descClass="number-txt"
                                        Description="The early short step of UI/UX design process - the wireframing when the structure of a project is being formed. Once the bones of the design have been approved, we move to ..."
                                    />
                                </div>
                                <div className="col-md-6 sm-mb-70">
                                    <SingleProcessTwo
                                        itemClass="addon-number"
                                        prefixClass="number-area plum-bg"
                                        prefix="3"
                                        titleClass="title"
                                        Title="Development"
                                        descClass="number-txt"
                                        Description="Still we’ll work with you to make sure our creative concepts align perfectly with your brand and your goals. No idea is too big, no project too hard."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SingleProcessTwo
                                        itemClass="addon-number"
                                        prefixClass="number-area pink-bg"
                                        prefix="4"
                                        titleClass="title"
                                        Title="Project Launch"
                                        descClass="number-txt"
                                        Description="When everything is ready, we’ll let it loose upon the world, but it’s not a time to crack the champagne just yet! We will support your project for 3 years for free!"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;