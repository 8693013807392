import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [service, setServices] = useState(false)
	const [servicePages, setServicePages] = useState(false)
	const [ourwork, setHowWeWork] = useState(false)
	const [contact, setContact] = useState(false)
	

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setServices(false)
			setServicePages(false)
			setHowWeWork(false)
			setContact(false)
		}
		
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setServices(false)
			setServicePages(false)
			setHowWeWork(false)
			setContact(false)
		}
		else if (menu === 'service') {
			setHome(false)
			setAbout(false)
			setServices(!service)
			setServicePages(false)
			setHowWeWork(false)
			setContact(false)
		}
		
		else if (menu === 'servicePages') {
			setHome(false)
			setAbout(false)
			setServices(false)
			setServicePages(!servicePages)
			setHowWeWork(false)
			setContact(false)
		}
		else if (menu === 'ourwork') {
			setHome(false)
			setAbout(false)
			setServices(false)
			setServicePages(false)
			setHowWeWork(!ourwork)
			setContact(false)
		}
		
		else if (menu === 'contact') {
			setHome(false)
			setAbout(false)
			setServices(false)
			setServicePages(false)
			setHowWeWork(false)
			setContact(!contact)
		}
		
		
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li className={home ? "current-menu-item" : ""}>
							<Link to="/home" className={activeMenu === "/home" ? "active-menu" : ""}>Home</Link>															
						</li>
						<li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
						</li>
						
						<li className={service ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="/service" onClick={() => { openMobileMenu('service'); }}>Services</Link>
							<ul className={service ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/service/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
								</li>
								<li>
									<Link to="/service/software-development" className={activeMenu === "/software-development" ? "active-menu" : ""}>Software Development</Link>
								</li>
								<li>
									<Link to="/service/app-development" className={activeMenu === "/app-development" ? "active-menu" : ""}>App Development</Link>
								</li>
								<li>
									<Link to="/service/hosting-cloud-services" className={activeMenu === "/hosting-cloud-services" ? "active-menu" : ""}>Cloud and DevOps</Link>
								</li>
								<li>
									<Link to="/service/branding" className={activeMenu === "/branding" ? "active-menu" : ""}>Company Branding</Link>
								</li>
								<li>
									<Link to="/service/analytic-solutions" className={activeMenu === "/analytic-solutions" ? "active-menu" : ""}>Analytic Solutions</Link>
								</li>
							</ul>
						</li>
						<li>
							<Link to="/ourwork" className={activeMenu === "/ourwork" ? "active-menu" : ""}>How We Work</Link>
						</li>
						<li>
							<Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default MobileMenu;