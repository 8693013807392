import React from 'react';

import SectionTitle from '../../components/Common/SectionTitle';
import SingleService1 from '../../components/Service/SingleServiceTwo1';
import SingleService2 from '../../components/Service/SingleServiceTwo2';
import SingleService3 from '../../components/Service/SingleServiceTwo3';
import SingleService4 from '../../components/Service/SingleServiceTwo4';
import SingleService5 from '../../components/Service/SingleServiceTwo5';
import SingleService6 from '../../components/Service/SingleServiceTwo6';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/icons/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/icons/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/icons/main-img/2.png';
import hoverIcon2 from '../../assets/img/service/icons/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/icons/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/icons/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/icons/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/icons/hover-img/4.png';
import mainIcon5 from '../../assets/img/service/icons/main-img/5.png';
import hoverIcon5 from '../../assets/img/service/icons/hover-img/5.png';
import mainIcon6 from '../../assets/img/service/icons/main-img/6.png';
import hoverIcon6 from '../../assets/img/service/icons/hover-img/6.png';

const Service = () => {

    return (
        <div id="rs-service" className="services style3 modify0 pt-110 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text"
                    subtitle="Services"
                    titleClass="title"
                    title="We Offer Trending Web Solutions Services"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService1
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={hoverIcon1}
                            serviceURL="web-development"
                            Title="Web Development"
                            Text="Following latest web development trends, we develop: 1. Progressive Web Apps (PWAs) 2. AI integrated web apps 3. Optimized Voice Search 4. Motion User Interface (UI) 5. Mobile&Tablet-friendly Development 6. Dark Mode Standardization 7. Improved Native Cybersecurity.                                                                    "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService2
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIcon2}
                            hoverIcon={hoverIcon2}
                            serviceURL="software-development"
                            Title="Software Development"
                            Text="Using cutting-edge technologies, IntellApps develops web-integrated software or integrates existing software to cloud-based websites. IntellApps is leading company in web-integration of software."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService3
                            itemClass="services-item"
                            prefix="03"
                            mainIcon={mainIcon3}
                            hoverIcon={hoverIcon3}
                            serviceURL="app-development"
                            Title="App Development"
                            Text="Mobile app development industry is continuously evolving. to stay competitive and deliver the products that will be in demand among modern consumers, IntellApps keeps up with the mobile app development trends."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService4
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIcon4}
                            hoverIcon={hoverIcon4}
                            serviceURL="clould-devOps"
                            Title="Hosting & Cloud Services"
                            Text="We provide powerful (free) SSD web hosting on top of our web development service to get your business online without a care. IntellApps offers cloud computing services which includes all infrastructure, platforms, software, or technologies."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService5
                            itemClass="services-item"
                            prefix="05"
                            mainIcon={mainIcon5}
                            hoverIcon={hoverIcon5}
                            serviceURL="analytic-solutions"
                            Title="Analytic Solutions"
                            Text="Companies need to make fact-based decisions daily and in multiple sectors of their organization. Much of this decision making is data driven, but not all business professionals are data savvy. Self-service data analytics solutions allow those without a technical background or in-depth knowledge of data analytics to access data and create or customize their own reports and analyses."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService6
                            itemClass="services-item"
                            prefix="06"
                            mainIcon={mainIcon6}
                            hoverIcon={hoverIcon6}
                            serviceURL="branding"
                            Title="Company Branding"
                            Text="Our company branding strategy is an all-encompassing guide to build a better, more effective online presence. Digital strategies are utilized to improve the performance websites, mobile apps, marketing campaigns, paid advertisements, and more. Common goals include increasing organic website traffic and campaign revenue."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Service;