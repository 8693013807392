import React, { Component } from 'react'
import SectionTitle from '../../components/Common/SectionTitle'
import { Link } from 'react-router-dom';

const PriceOne =() => {
    

        return (
            
            <section className="price-wrap section-padding pt-0" id="price">
                <div id="rs-service" className="services main-home pt-110 pb--30 md-pt-70 md-pb-70">
                    <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-46"
                            subtitleClass="sub-text style-bg"
                            subtitle="Services"
                            titleClass="title"
                            title="We Offer Trending Web Solutions Services"
                            effectClass="heading-line"
                        />
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <div className="tab-content">
                                    <div id="month" className="tab-pane in active">
                                        <div className="row">
                                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                                <div className="single-price-table" data-aos="fade-up" data-aos-delay={300} data-aos-duration={800}>
                                                    <div className="package-name text-center">
                                                        <h4>Web Development</h4>
                                                        <span>We Create Websites</span>
                                                    </div>
                                                    <div className="package-features-price">
                                                        <ul> 
                                                            <li><i className="fal fa-check-circle"/>Following latest web development trends, we develop: 1. Progressive Web Apps (PWAs) 2. AI integrated web apps 3. Optimized Voice Search 4. Motion User Interface (UI) 5. Mobile&Tablet-friendly Development 6. Dark Mode Standardization 7. Improved Native Cybersecurity.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="package-btn">
                                                        <Link to="./service/web-development">
                                                            Learn more
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                                <div className="single-price-table" data-aos="fade-up" data-aos-delay={300} data-aos-duration={800}>
                                                    <div className="package-name text-center">
                                                        <h4>Software Development</h4>
                                                        <span>We Build Softwares</span>
                                                    </div>
                                                    <div className="package-features-price">
                                                        <ul>
                                                            <li><i className="fal fa-check-circle" />Using cutting-edge technologies, IntellApps develops web-integrated software or integrates existing software to cloud-based websites. IntellApps is leading company in web-integration of software.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="package-btn">
                                                        <Link to="./service/software-development">
                                                            Learn more
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                                <div className="single-price-table" data-aos="fade-up" data-aos-delay={400} data-aos-duration={800}>
                                                    <div className="package-name text-center">
                                                        <h4>App Development</h4>
                                                        <span>We Design Apps</span>
                                                    </div>
                                                    <div className="package-features-price">
                                                        <ul>
                                                            <li><i className="fal fa-check-circle" />Mobile app development industry is continuously evolving. to stay competitive and deliver the products that will be in demand among modern consumers, IntellApps keeps up with the mobile app development trends.</li>
                                                        
                                                        </ul>
                                                    </div>
                                                    <div className="package-btn">
                                                        <Link to="./service/app-development">
                                                            Learn more
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                                <div className="single-price-table" data-aos="fade-up" data-aos-delay={400} data-aos-duration={800}>
                                                    <div className="package-name text-center">
                                                        <h4>Hosting & Cloud Services</h4>
                                                        <span>We Host Websites</span>
                                                    </div>
                                                    <div className="package-features-price">
                                                        <ul>
                                                            <li><i className="fal fa-check-circle" />We provide powerful (free) SSD web hosting on top of our web development service to get your business online without a care. IntellApps offers cloud computing services which includes all infrastructure, platforms, software, or technologies.</li>
                                                            
                                                        </ul>
                                                    </div>
                                                    <div className="package-btn">
                                                        <Link to="./service/hosting-cloud-services">
                                                            Learn more
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                                <div className="single-price-table" data-aos="fade-up" data-aos-delay={400} data-aos-duration={800}>
                                                    <div className="package-name text-center">
                                                        <h4>Analytic Solutions</h4>
                                                        <span>We Add Analytics</span>
                                                    </div>
                                                    <div className="package-features-price">
                                                        <ul>
                                                            <li><i className="fal fa-check-circle" />Companies need to make fact-based decisions daily. Much of this decision making is data driven, but not all business professionals are data savvy. Self-service data analytics solutions allow those without a technical background or in-depth knowledge of data analytics to access data and create or customize their own reports and analyses.</li>
                                                            
                                                        </ul>
                                                    </div>
                                                    <div className="package-btn">
                                                        <Link to="./service/analytic-solutions">
                                                            Learn more
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                                <div className="single-price-table" data-aos="fade-up" data-aos-delay={400} data-aos-duration={800}>
                                                    <div className="package-name text-center">
                                                        <h4>Company Branding</h4>
                                                        <span>We Power Businesses</span>
                                                    </div>
                                                    <div className="package-features-price">
                                                        <ul>
                                                            <li><i className="fal fa-check-circle" />Our company branding strategy is an all-encompassing guide to build a better, more effective online presence. Digital strategies are utilized to improve the performance websites, mobile apps, marketing campaigns, paid advertisements, and more. Common goals include increasing organic website traffic and campaign revenue.</li>
                                                            
                                                        </ul>
                                                    </div>
                                                    <div className="package-btn">
                                                        <Link to="./service/branding">
                                                            Learn more
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    
}

export default PriceOne
