import React from 'react';
import HeaderStyle from '../../components/Layout/Header/HeaderStyle';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import HomeMain from './HomeMain';

const HomeSeven = () => {
    return (
        <React.Fragment>
            <HeaderStyle
                parentMenu='home'
                secondParentMenu='multipage'
                activeMenu='/home'
            />
            <HomeMain />
            <Footer footerClass="footer" />
            <SearchModal />
        </React.Fragment>
    );
}


export default HomeSeven;