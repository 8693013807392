import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import VideoStyleThree from '../../components/Video/VideoStyleThree';
import OurExpertise from "./OurExpertise";
import ExtraFeatures1 from "./ExtraFeatures1";
import ExtraFeatures2 from "./ExtraFeatures2";
import Project from './ProjectSection';

import aboutImg from '../../assets/img/about/about-3.png';
import danceEffectImg from '../../assets/img/about/effect-1.png';


import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';

const AboutMain = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="about" className="about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 md-mb-30">
                            <div className="animation-shape">
                                <div className="images">
                                    <img src={aboutImg} alt="" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="About Us"
                                    titleClass="title pb-36"
                                    title="We Increase Business Successes with Latest Technologies"
                                    descClass="desc pb-34"
                                    description="Considering trending user needs, we develop web applications and mobile apps for customers in New Zealand and all over the world."
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription="We specialize in helping all businesses to establish their digital brand and dominate the competition with modern and smart design. Our platform merges dynamic web development with cutting edge marketing tools to create a powerful website for your business that performs better than it looks."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/service">Learn-More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div>
                </div>
            </div>
            {/* about-area-end */}

            {/* ExtraFeatures1-start */}
            <ExtraFeatures1 />
            {/* ExtraFeatures1-start */}

            {/* ExtraFeatures2-start */}
            <ExtraFeatures2 />
            {/* ExtraFeatures2-start */}

            {/* OurTeam-start */}
            <OurExpertise />
            {/* OurTeam-end */}

            {/* Project-area-start */}
            <Project />
            {/* Project-area-end */}

            {/* VideoStyleThree area start */}
            <div class="contact-wrap bg7 pt-120 pb-390 md-pt-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Contact"
                        titleClass="title white-color"
                        title="Request Free Consultation"
                    />
                </div>
            </div>
            <VideoStyleThree />
            {/* VideoStyleThree area end */}

        </React.Fragment>
    )
}

export default AboutMain;