import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import PortfolioTabTwo from './PortfolioTabTwo'


const About = () => {

    return (
        <React.Fragment>
            <div id="blog" className="process style6 white-bg pt-120 pb-120 md-pt-70 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title4 mb-50 text-center"
                        subtitleClass="sub-text new-text"
                        subtitle="A full range of services to achieve your goals"
                        titleClass="title new-title"
                        title="Effective Growth Strategy Through Converting Website To Online Marketing."
                    />

                    <PortfolioTabTwo />

                </div>
            </div>
        </React.Fragment>
    )
}

export default About