import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ScrollToTop from '../../components/Common/ScrollTop';
import Process from './AboutSection';
import ProcessSection from './ProcessSection';
import ExtraFeatures from "./ExtraFeatures";


// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/3.jpg';

const ServiceThree = () => {

    return (
        <React.Fragment>
            <Header
                parentMenu='ourwork'
                activeMenu='/ourwork'
            />
            <div>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="How We Work?"
                    titleClass="page-title"
                    pageName="How We Work?"
                    breadcrumbsImg={bannerbg}
                />
                {/* breadcrumb-area-start */}

                {/* Process-area-start */}
			    <Process />
			    {/* Process-area-end */}

                {/* working-process-area-start */}
			    <ProcessSection />
			    {/* working-process-area-end */}

                {/* ExtraFeatures1-start */}
                <ExtraFeatures />
                {/* ExtraFeatures1-start */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </div>
            <Footer />
            <SearchModal />
        </React.Fragment>

    );
}


export default ServiceThree;