import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import Typewriter from "typewriter-effect";

// Service Image Icons
import mainIcon1 from '../../assets/img/service/icons/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/icons/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/icons/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/icons/hover-img/4.png';
import mainIcon5 from '../../assets/img/service/icons/hover-img/5.png';
import mainIcon6 from '../../assets/img/service/icons/hover-img/6.png';


const BannerHome = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    const [strings, setStrings] = useState(["for your success", "for your goals", "for your happiness"]);

    const [activeCardIndex, setActiveCardIndex] = useState(null);

    const cards = [
        { id: 1, icon: mainIcon1, url: "/service/web-development", title: "Web Development" },
        { id: 2, icon: mainIcon2, url: "/service/software-development", title: "Software Development" },
        { id: 3, icon: mainIcon3, url: "/service/service/app-development", title: "App Development" },
        { id: 4, icon: mainIcon4, url: "/service/service/hosting-cloud-services", title: "Hosting & Cloud Services" },
        { id: 5, icon: mainIcon5, url: "/service/branding", title: "Analytic Solutions" },
        { id: 6, icon: mainIcon6, url: "/service/analytic-solutions", title: "Company Branding" }
      ];

    const handleCardClick = (index) => {
        setActiveCardIndex(index);
    };

    return (
        <React.Fragment>
            {/*<!-- banner section start -->*/}
            <div className="banner  style4">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='7EbPcXxvbos' onClose={() => { openModal(); }} />
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-7	col-md-7 col-lg-7 col-xl-7 col-xxl-7 banner-content">
                            <span className="sub-title">INTELLIGENT WEB, Mobile &amp; ON-PREMISES Applications</span>
                            <h1 className="title">Complete intelligent applications
                                <span style={{ color: "#00cc00" }}>
                                    <Typewriter
                                        options={{
                                            wrapperClassName: "color-font fw-600",
                                            strings: strings,
                                            autoStart: true,
                                            loop: true,
                                            onComplete: (self) => {
                                            setStrings([...strings, "for your goals"]);
                                            self.deleteAll().start();
                                            },
                                        }}
                                    />
                                </span>
                            </h1>
                            <p className="desc">
                                We are a leading Intelligent Applications Developing Agency based in New Zealand, with a global presence and a strong commitment to serving our local clients.
                            </p>
                            <ul className="banner-btn">
                                <li><Link className="readon discover" to="/about">Discover More</Link></li>
                                <li>
                                    <div className="videos">
                                        <div className="animate-border white-color">
                                            <a className="popup-border popup-videos" onClick={() => { openModal(); }}>
                                                <i className="fa fa-play"></i>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-0 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-xxl-5 banner-content-right">
                            <div className="card-stack">
                                {cards.map((card, index) => (
                                    <div
                                        key={card.id}
                                        className={`card ${index === activeCardIndex ? "active" : ""}`}
                                        onClick={() => handleCardClick(index)}
                                        >
                                        <div className='card-icon'>
                                            <img src={card.icon}/>
                                        </div>
                                        <Link className="card-title" to={card.url}>{card.title}</Link>
                                        <p className="card-footer">OUR SERVICES</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!-- banner section end -->*/}
        </React.Fragment>
    );
}

export default BannerHome;