import React from 'react';
import Header from '../../components/Layout/Header';
import BannerHomeEleven from '../../components/Banner/BannerHomeThirteen';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/2.jpg';


import { Link } from 'react-router-dom';
import Brand from '../../components/Common/Brand';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService1';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';

// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import aboutImg1 from '../../assets/img/about/brand-visual.png';


const WebDevelopment = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='Services'
                activeMenu='/branding'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Company Branding'
                pageCategory='Services'
                pageName='Company Branding'
            />
            {/* breadcrumb-area-end */}
            {/* About Section Start */}
            <div className="about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Company Branding"
                                titleClass="title"
                                title="We Help to Implement Your Ideas into Automation"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Developing your brand is something that is an absolute must these days, since 75% of consumers worldwide list brand origin as an essential purchase driver."
                                secondDescClass="desc"
                                secondDescription="Online branding serices that we provide are procedures of building the identity of any brand using a website, Google, Facebook, Instagram, blog post, and other online marketing channels. This process helps expand the exposure of the brand’s identity on the web to widen the reach of the brand, products, or services in front of potential customers."
                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}


            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
            <Footer footerClass="footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default WebDevelopment;