import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, activeMenu } = props;

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
                <Link to="" as="/">Home</Link>                
            </li>
            
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
            </li>

            <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/service" as="/service">Services</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/service/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
                    </li>
                    <li>
                        <Link to="/service/software-development" className={activeMenu === "/software-development" ? "active-menu" : ""}>Software Development</Link>
                    </li>
                    <li>
                        <Link to="/service/app-development" className={activeMenu === "/app-development" ? "active-menu" : ""}>App Development</Link>
                    </li>
                    <li>
                        <Link to="/service/hosting-cloud-services" className={activeMenu === "/hosting-cloud-services" ? "active-menu" : ""}>Hosting & Cloud Services</Link>
                    </li>
                    <li>
                    <Link to="/service/branding" className={activeMenu === "/branding" ? "active-menu" : ""}>Company Branding</Link>
                    </li>
                    <li>
                        <Link to="/service/analytic-solutions" className={activeMenu === "/analytic-solutions" ? "active-menu" : ""}>Analytic Solutions</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'ourwork' ? 'current-menu-item' : ''}>
                <Link to="/ourwork" className={activeMenu === "/ourwork" ? "active-menu" : ""}>How We Work</Link>              
            </li>
            
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;